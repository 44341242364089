<template>
  <div style="padding: 0px;">
    <van-nav-bar
        :title="(click_item.name === undefined ? '产品详情' : click_item.name)"
        left-arrow
        :border="false"
        :fixed="true"
        @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-divider></van-divider>
    <div style="height: 100%; width: 100%;padding: 20px 0px 0px;background-color: #F7F8FA;box-sizing:border-box;">
      <van-row gutter="15" style="padding-left: 10px;padding-right: 10px;">
<!--        <van-col span="24">-->
<!--          <span style="font-size: 24px; color: black;font-weight: bolder">{{ (click_item.name === undefined ? '' : click_item.name) }}</span>-->
<!--        </van-col>-->
        <van-col span="24" v-if="false">
          <van-row  gutter="15" style="margin-top: 10px;margin-bottom: 10px;font-size: 14px; color: #aaaaaa">
            <van-col span="8">
              <span>{{ (click_item.filing_code === undefined ? '' : click_item.filing_code) }}</span>
            </van-col>
            <van-col span="8">
              <span>{{ (click_item.filing_agency === undefined ? '' : click_item.filing_agency) }}</span>
            </van-col>
            <van-col span="8">
              <span>{{ (click_item.type === undefined ? '' : click_item.type) }}</span>
            </van-col>
          </van-row>
        </van-col>

        <van-col span="24">
          <van-cell-group inset title="基金档案">
            <van-cell title="起售金额" :value="(click_item.start_amount === undefined ? '' : click_item.start_amount + '万')" />
            <van-cell title="最大规模" :value="(click_item.largest === undefined ? '' : click_item.largest + '万')" />
            <van-cell title="风险等级" :value="(click_item.risk_level === undefined ? '' : 'C'+click_item.risk_level)" />
            <van-cell title="锁定期" :value="(click_item.lockup_period === undefined ? '' : click_item.lockup_period+ '天')" />
            <van-cell title="备案编号" :value="(click_item.filing_code === undefined ? '' : click_item.filing_code)" />
            <van-cell title="备案机构" :value="(click_item.filing_agency === undefined ? '' : click_item.filing_agency)" />
            <van-cell title="产品类别" :value="(click_item.type === undefined ? '' : click_item.type)" />
          </van-cell-group>
        </van-col>

        <van-col span="24">
          <van-cell-group inset title="基金日期">
            <van-cell title="开放日期" :value="(click_item.open_date === undefined ? '' : click_item.open_date)" />
            <van-cell title="募集期" :value="(click_item.raising_period === undefined ? '' : click_item.raising_period)" />
            <van-cell title="截止打款" :value="(click_item.end_date === undefined ? '' : click_item.end_date)" />
          </van-cell-group>
        </van-col>

        <!--      基金费用-->
        <van-col span="24">
          <van-cell-group inset title="基金费用">
            <van-cell title="超额报酬" :value="(click_item.excess_return === undefined ? '' : click_item.excess_return + '%')" />
            <van-cell title="报酬计提基准" :value="(click_item.accrual_basis === undefined ? '' : click_item.accrual_basis+ '%')" />
            <van-cell title="管理费" :value="(click_item.manage_fee === undefined ? '' : click_item.manage_fee + '%')" />
            <van-cell title="托管费" :value="(click_item.custody_fee === undefined ? '' : click_item.custody_fee + '%')" />
            <van-cell title="外包费" :value="(click_item.outsourcing_fee === undefined ? '' : click_item.outsourcing_fee + '%')" />
          </van-cell-group>
        </van-col>

        <!--        <van-col span="24">-->
        <!--          <van-divider content-position="left">基金净值</van-divider>-->
        <!--        </van-col>-->
        <van-col span="24">
          <line-chart :chart-data="chartData" />
        </van-col>

        <van-col span="24">
          <van-cell-group inset title="基金管理">
            <van-cell title="管理人" :value="(click_item.manager === undefined ? '' : click_item.manager)" />
            <van-cell title="托管人" :value="(click_item.custodian === undefined ? '' : click_item.custodian)" />
            <van-cell title="外包服务机构" :value="(click_item.service_organization === undefined ? '' : click_item.service_organization)" />
          </van-cell-group>
        </van-col>
        <van-col span="24">
          <div>
            <van-divider>基金介绍</van-divider>
            <div style="padding: 10px;" v-html="(click_item.description === undefined) ?'':click_item.description" @click="imageChgange($event)"></div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import LineChart from "./LineChart";
import qs from "qs";
import {Base64} from "js-base64";

export default {
  name: "ProductDetail",
  components: {
    LineChart
  },
  data() {
    return {
      click_item: undefined,
      chartData: {
        expectedData:[],
        actualData:[],
        time:[],
      }
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    netWorthData () {
      this.$http.get('/product/net_worth_update/' + this.click_item.id + '/', { product_id: this.click_item.product_id })
          .then(res => {
            if (res && res.code === 1000) {
             const data = res.data
              if (data && data.length>0) {
                this.chartData.actualData = data.map(item => {
                  return (item.net_worth * 0.0001).toFixed(4);
                });

                this.chartData.time = data.map(item => {
                  return item.update_date.replaceAll('T', '');
                });

                // if (this.chartData.time) {
                //   const length = this.chartData.time.length
                //   const times = [...this.chartData.time]
                //   for (let i = 0; i < length; i++) {
                //     const next = i+1
                //     if (next<length){
                //       if (times[i].substr(0,10) === times[next].substr(0,10)) {
                //         this.chartData.time[next] = times[next].substr(11,times[next].length)
                //       }
                //     }
                //   }
                // }

                console.log(this.chartData.time);
              }
            }
          })
    }
  },
  created() {
    // console.log(this.$route.query.data);
    this.click_item = qs.parse(Base64.decode(this.$route.query.data))
    console.log(this.click_item);
    this.netWorthData();
  }
}
</script>

<style scoped>

</style>
